import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Image from "../components/image";

const AboutPage = () => (
  <Layout>
    <SEO title="About Thomas" />
    <Image />
    <h1
      className="h2"
      style={{
        marginBottom: `1em`,
      }}
    >
      About Me
    </h1>
    <p
      style={{
        marginBottom: 0,
        fontWeight: 700,
      }}
    >
      Hi there
    </p>
    <p></p>

    <Link to="/">Go back to the Thomas' resume</Link>
  </Layout>
);

export default AboutPage;
